import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetCourseExpandCollapse } from '../../../data-grid-container/courses/types/courses.types';
import { manageCourseStrandIsExpanded } from '../../helpers/courses/course-expand-helpers';
import {
    GridCells,
    GridCounters,
    ToggleActivityGridCheckbox,
    ToggleGridCell,
    ToggleStudentGridCheckbox,
    ToggleTopicGridCheckbox
} from '../../helpers/slice/topic/topic-slice.types';
import {
    ChallengesConstants,
    ChallengesState
} from './challenges.types';
import { getInitDokCourse } from './components/challenges-course-filters/challenges-course-filters-helpers';
import {
    DokFilterCourse
} from './components/challenges-course-filters/components/dok-filter/dok-filter.types';
import {
    defaultLevelFilterValue
} from './components/challenges-course-filters/components/level-filter/level-filter-constants';
import { RootState } from '@config/react-redux/store';
import { resetGridCells } from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    resetGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import { RouteHelpersModalConstants } from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { toggleGridCells } from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers';
import { defaultState } from '@containers/activity-router/routes/helpers/slice/slice-defaults';
import {
    toggleRouteActivityGridCheckbox,
    toggleRouteStudentGridCheckbox,
    toggleRouteTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/slice-helpers';
import {
    manageChallengesCellAssignmentsSelected,
    manageChallengesSelectedAssignments,
    updateChallengesAssignments
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/assignments/selected/challenges-assignments-selected-helpers';
import {
    getChallengesDokFilteredCourse
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-dok-filtered-course';
import {
    getChallengesLevelFilteredCourse
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-level-filtered-course';
import {
    TransformCourse,
    TransformedChallengesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const { name } = ChallengesConstants;
const initialState: ChallengesState = {
    ...defaultState,
    course: {
        selected: null,
        filtered: null
    },
    filters: {
        level: defaultLevelFilterValue,
        dok: null
    },
    assignments: {
        ...defaultState.assignments,
        grid: {}
    },
    modal: null
};

export const challengesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setChallengesCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
            state.course.filtered = action.payload;
        },
        setChallengesCourseIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (state.course.selected) {
                state.course.selected.topics = manageCourseStrandIsExpanded({
                    course: state.course.selected,
                    payload: action.payload
                });
            }

            if (state.course.filtered) {
                state.course.filtered.topics = manageCourseStrandIsExpanded({
                    course: state.course.filtered,
                    payload: action.payload
                });
            }
        },
        setChallengesGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        setChallengesGridCountersInitialValue (state, action: PayloadAction<GridCounters>) {
            state.grid.counters = action.payload;
        },
        toggleChallengesGridCells (state, action: PayloadAction<ToggleGridCell>) {
            const {
                student,
                ...payload
            } = action.payload;
            const {
                gridCells,
                gridCounters
            } = toggleGridCells({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentId: student.userProfileId.toString(),
                ...payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;

            state.assignments.selected = manageChallengesCellAssignmentsSelected({
                ...action.payload,
                studentAssignments: state.assignments.grid,
                gridCells: state.grid.cells,
                selectedAssignments: state.assignments.selected
            });
        },
        toggleChallengesStudentGridCheckbox (state, action: PayloadAction<ToggleStudentGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteStudentGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleChallengesTopicGridCheckbox (state, action: PayloadAction<ToggleTopicGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteTopicGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleChallengesActivityGridCheckbox (state, action: PayloadAction<ToggleActivityGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteActivityGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        setChallengesAssignmentGrid (state, action: PayloadAction<TransformedChallengesAssignments>) {
            state.assignments.grid = action.payload;
        },
        resetChallengesAssignments (state) {
            state.assignments = {
                grid: {},
                selected: defaultState.assignments.selected
            };
        },
        resetChallengesGrid (state) {
            state.grid.cells = resetGridCells({
                gridCells: state.grid.cells,
                isSelected: false
            });
            state.grid.counters = resetGridCellCounters({
                counters: state.grid.counters
            });
        },
        resetChallengesSelectedAssignments (state) {
            state.assignments.selected = defaultState.assignments.selected;
        },
        setChallengesModal (state, action: PayloadAction<RouteHelpersModalConstants | null>) {
            state.modal = action.payload;
        },
        updateChallengesAssignmentSelected (state, action: PayloadAction<TransformedChallengesAssignments>) {
            state.assignments.selected = updateChallengesAssignments({
                updatedStudentAssignments: action.payload,
                selectedAssignments: state.assignments.selected,
                gridCells: state.grid.cells
            });
        },
        setChallengesFilterLevel (state, action: PayloadAction<number>) {
            const {
                updatedCourse,
                hasTopics
            } = getChallengesLevelFilteredCourse({
                course: state.course.selected,
                levelFilter: action.payload
            });

            state.filters.dok = getInitDokCourse({
                course: state.course.selected as TransformCourse,
                filterLevel: action.payload
            });
            state.course.filtered = updatedCourse;
            state.filters.level = hasTopics ? action.payload : defaultLevelFilterValue;
        },
        setChallengesFilterDok (state, action: PayloadAction<DokFilterCourse>) {
            const {
                updatedCourse,
                updatedFilterLevel
            } = getChallengesDokFilteredCourse({
                course: state.course.selected,
                dokCourseItems: action.payload
            });

            state.filters.dok = action.payload;
            state.filters.level = updatedFilterLevel || defaultLevelFilterValue;
            state.course.filtered = updatedCourse;
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setChallengesCourseSelected,
    setChallengesCourseIsExpanded,
    setChallengesGridCells,
    toggleChallengesStudentGridCheckbox,
    setChallengesGridCountersInitialValue,
    toggleChallengesGridCells,
    toggleChallengesTopicGridCheckbox,
    toggleChallengesActivityGridCheckbox,
    setChallengesAssignmentGrid,
    resetChallengesAssignments,
    resetChallengesGrid,
    resetChallengesSelectedAssignments,
    setChallengesModal,
    updateChallengesAssignmentSelected,
    setChallengesFilterLevel,
    setChallengesFilterDok
} = challengesSlice.actions;

/**
 * SELECTORS
 */
export const selectChallengesCourseSelected = (state: RootState) => state[name].course.selected;

export const selectChallengesCourseFiltered = (state: RootState) => state[name].course.filtered;

export const selectChallengesGridCounters = (state: RootState) => state[name].grid.counters;

export const selectChallengesGridCells = (state: RootState) => state[name].grid.cells;

export const selectChallengesAssignmentsGrid = (state: RootState) => state[name].assignments.grid;

export const selectChallengesAssignmentsSelected = (state: RootState) => state[name].assignments.selected;

export const selectChallengesModal = (state: RootState) => state[name].modal;

export const selectChallengesFilterLevel = (state: RootState) => state[name].filters.level;

export const selectChallengesFilterDok = (state: RootState) => state[name].filters.dok;

export default challengesSlice.reducer;
