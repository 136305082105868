import { defaultLevelFilterValue } from './components/level-filter/level-filter-constants';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { CheckboxStates } from '@containers/activity-router/data-grid-container/grid/grid.types';
import {
    DokFilterCourse,
    GetDokParentCheckboxState
} from '@containers/activity-router/routes/learn/challenges/components/challenges-course-filters/components/dok-filter/dok-filter.types';
import {
    getUpdatedChallengesLevelFilter
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-level-filtered-course';
import {
    ChallengesComplexity,
    TransformCourse
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

type GetInitDokCourseParams = {
    course: TransformCourse
    filterLevel: number
    dokFilter?: ChallengesComplexity
};

type GetFilteredLevelDataParams = {
    course: CourseData
};

type GetFilteredLevelDataReturn = {
    level: number | null
    count: number
};

type GetLevelDataCountParams = {
    course: CourseData
    level: number | null
};

export const getDokParentCheckboxState: GetDokParentCheckboxState = (items) => {
    const allChecked = items.every((topic) => topic.checked === CheckboxStates.checked);
    const allUnchecked = items.every((topic) => topic.checked === CheckboxStates.unchecked);

    if (allChecked) return CheckboxStates.checked;
    if (allUnchecked) return CheckboxStates.unchecked;

    return CheckboxStates.partial;
};

export const getInitDokCourse = ({
    course,
    filterLevel,
    dokFilter = ChallengesComplexity.ALL
}: GetInitDokCourseParams): DokFilterCourse => {
    return course.topics.map((strand) => {
        const updatedTopics = strand.topics?.map((topic) => {
            const updatedActivities = topic.activities.map((activity) => {
                const { complexity } = activity;
                const {
                    yearGroup,
                    yearGroupFromName,
                    yearGroupToName
                } = activity.extraProperties;
                const updatedFilterLevel = getUpdatedChallengesLevelFilter(filterLevel);
                const checkboxState =
                    (
                        (dokFilter === ChallengesComplexity.ALL || dokFilter === complexity) &&
                        (filterLevel === defaultLevelFilterValue || yearGroup === updatedFilterLevel)
                    ) ?
                        CheckboxStates.checked :
                        CheckboxStates.unchecked;

                return {
                    name: activity.name,
                    id: activity.id,
                    checked: checkboxState,
                    depthOfKnowledge: activity.complexity as ChallengesComplexity,
                    yearGroupFromName: yearGroupFromName || '',
                    yearGroupToName: yearGroupToName || ''
                };
            });

            return {
                id: topic.id,
                checked: getDokParentCheckboxState(updatedActivities),
                name: topic.name,
                isExpanded: false,
                activities: updatedActivities
            };
        }) || [];

        return {
            id: strand.id,
            checked: getDokParentCheckboxState(updatedTopics),
            name: strand.name,
            isExpanded: false,
            topics: updatedTopics,
            strandIndex: strand.strandIndex
        };
    });
};

const getLevelDataCount = ({ course, level }: GetLevelDataCountParams) => {
    if (!course || level === null) {
        return 0;
    }

    let total = 0;

    for (const strand of course.topics) {
        for (const topic of strand?.topics || []) {
            for (const activity of topic.activities) {
                const { yearGroup } = activity.extraProperties;

                if (yearGroup === level) {
                    total++;
                }
            }
        }
    }

    return total;
};
const getFilteredLevelData = ({ course }: GetFilteredLevelDataParams): GetFilteredLevelDataReturn => {
    let level: number | null = null;
    let count = 0;

    if (course) {
        for (const strand of course.topics) {
            for (const topic of strand?.topics || []) {
                for (const activity of topic.activities) {
                    const { yearGroup } = activity.extraProperties;

                    if (level === null) {
                        level = yearGroup;
                        count++;
                    } else if (level === yearGroup) {
                        count++;
                    } else {
                        return {
                            level: null,
                            count: 0
                        };
                    }
                }
            }
        }
    }

    return {
        level,
        count
    };
};

export const updatedFilterLevel = ({ course }: { course: CourseData }) => {
    const {
        level,
        count
    } = getFilteredLevelData({ course });
    const totalCount = getLevelDataCount({
        course,
        level
    });

    return count > 0 && count === totalCount ? level : null;
};
