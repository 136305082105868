import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { LevelFilter } from '@containers/activity-router/routes/learn/challenges/challenges-constants';
import { TransformCombinedAssignTopic } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

type GetChallengesLevelFilteredCourseProps = {
    course: CourseData
    levelFilter: number
};

type GetFilteredActivitiesParams = {
    topic: TransformCombinedAssignTopic
};

export const getUpdatedChallengesLevelFilter = (levelFilter: number) => {
    const {
        MIN_LEVEL,
        MAX_LEVEL
    } = LevelFilter;

    if (levelFilter === MIN_LEVEL) levelFilter++;
    else if (levelFilter === MAX_LEVEL) levelFilter--;

    return levelFilter;
};

export const getChallengesLevelFilteredCourse = ({
    course,
    levelFilter
}: GetChallengesLevelFilteredCourseProps) => {
    if (!course) return {
        updatedCourse: course,
        hasTopics: true
    };

    const getFilteredActivities = ({ topic }: GetFilteredActivitiesParams) => {
        return topic.activities.filter((activity) => {
            const { yearGroup } = activity.extraProperties;
            const updatedLevelFilter = getUpdatedChallengesLevelFilter(levelFilter);
            const equalToLevelFilter = yearGroup === updatedLevelFilter;
            const selectAllLevelFilter = updatedLevelFilter === LevelFilter.SELECT_ALL;

            return (
                equalToLevelFilter ||
                selectAllLevelFilter
            );
        });
    };
    const filteredCourse: CourseData = {
        ...course,
        topics: course.topics?.map((strand) => ({
            ...strand,
            topics: strand.topics?.map((topic) => ({
                ...topic,
                activities: getFilteredActivities({ topic })
            })).filter((topic) => topic.activities.length > 0) || null
        })).filter((strand) => (strand.topics?.length || 0) > 0) || null
    };
    const hasTopics = filteredCourse.topics.length > 0;
    const selectedCourse = hasTopics ? filteredCourse : course;
    const updatedCourse = {
        ...selectedCourse,
        topics: selectedCourse.topics.map((strand, index) => ({
            ...strand,
            topics: strand?.topics?.map((topic) => ({
                ...topic,
                isExpanded: index === 0
            })) || null,
            isExpanded: index === 0
        })) || null
    };

    return {
        updatedCourse,
        hasTopics
    };
};
