import {
    getActivityKey,
    getStudentKey,
    getTopicKey
} from '../../keys/key-generator';
import { GridCells } from '../../slice/topic/topic-slice.types';
import {
    GetTopicGridCellMap,
    GetTopicGridCells,
    ManageGridCellMapActivity,
    ManageGridCellMapTopic,
    ManageGridCellsParams,
    ResetGridCellsProps
} from './grid-cells-helpers.types';
import {
    TransformCombinedAssignTopic,
    TransformCourseTopics
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export function getTopicGridCells ({
    topicGridCells,
    isSelected
}: GetTopicGridCells) {
    Object.values(topicGridCells).forEach(gridCell => gridCell.isSelected = isSelected);

    return topicGridCells;
}

export function manageGridCells ({
    topicId,
    activityId,
    studentId,
    gridCells,
    isSelected
}: ManageGridCellsParams) {
    if (!activityId) {
        gridCells[studentId][topicId]= getTopicGridCells({
            topicGridCells: gridCells[studentId][topicId],
            isSelected
        });
    } else if (activityId) {
        gridCells[studentId][topicId][activityId].isSelected = isSelected;
    }

    return gridCells;
}

function manageGridCellMapActivity ({
    updatedGridCells,
    activities,
    studentKey,
    topicKey
}: ManageGridCellMapActivity) {
    activities.forEach(({
        id: activityId,
        activityType
    }) => {
        const activityKey = getActivityKey({
            id: activityId,
            activityType
        });

        updatedGridCells[studentKey][topicKey] = {
            ...updatedGridCells[studentKey][topicKey],
            [activityKey]: {
                isSelected: false
            }
        };
    });

    return updatedGridCells;
}

function manageGridCellMapTopic ({
    updatedGridCells,
    topics,
    studentKey
}: ManageGridCellMapTopic) {
    function processStrand ({ topics: strandTopics, id: strandId }: TransformCourseTopics) {
        if (strandTopics) {
            strandTopics.forEach(topic => {
                processTopic(topic, strandId);
            });
        }
    }

    function processTopic (topic: TransformCombinedAssignTopic, strandId?: string) {
        if (topic.activities) {
            const topicKey = getTopicKey({
                topicId: topic.id,
                strandId
            });

            updatedGridCells[studentKey] = {
                ...updatedGridCells[studentKey],
                [topicKey]: {}
            };

            updatedGridCells = manageGridCellMapActivity({
                updatedGridCells,
                activities: topic.activities || [],
                studentKey,
                topicKey
            });
        }
    }

    topics.forEach(topic => {
        if (topic.topics) {
            processStrand(topic);
        } else {
            processTopic(topic as TransformCombinedAssignTopic);
        }
    });

    return updatedGridCells;
}

export function getTopicGridCellsMap ({
    selectedGroupStudents,
    topics
}: GetTopicGridCellMap) {
    let updatedGridCells: GridCells = {};

    selectedGroupStudents.forEach(({ userProfileId }) => {
        const studentKey = getStudentKey({ id: userProfileId.toString() });

        updatedGridCells[studentKey] = {};

        updatedGridCells = manageGridCellMapTopic({
            updatedGridCells,
            topics,
            studentKey
        });
    });

    return updatedGridCells;
}

export function resetGridCells ({
    gridCells,
    isSelected
}: ResetGridCellsProps) {
    Object.values(gridCells).forEach(student => {
        Object.values(student).forEach(topic => {
            Object.values(topic).forEach(activity => activity.isSelected = isSelected);
        });
    });

    return gridCells;
}
