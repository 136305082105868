import { updatedFilterLevel } from '../../../components/challenges-course-filters/challenges-course-filters-helpers';
import { DokFilterCourse } from '../../../components/challenges-course-filters/components/dok-filter/dok-filter.types';
import { defaultLevelFilterValue } from '../../../components/challenges-course-filters/components/level-filter/level-filter-constants';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { CheckboxStates } from '@containers/activity-router/data-grid-container/grid/grid.types';

type GetChallengesDokFilteredCourseProps = {
    course: CourseData
    dokCourseItems: DokFilterCourse
};

type GetChallengesDokFilteredCourseReturn = {
    updatedCourse: CourseData
    updatedFilterLevel: number | null
};

export const getChallengesDokFilteredCourse = ({
    course,
    dokCourseItems
}: GetChallengesDokFilteredCourseProps): GetChallengesDokFilteredCourseReturn => {
    if (!course) return {
        updatedCourse: course,
        updatedFilterLevel: defaultLevelFilterValue
    };

    const updatedCourse = {
        ...course,
        topics: course.topics.map((strand) => {
            const dokStrandItem = dokCourseItems.find((dokStrand) => dokStrand.id === strand.id);
            const updatedTopics = strand.topics?.map((topic) => {
                const dokTopicItem = dokStrandItem?.topics.find((dokTopic) => dokTopic.id === topic.id);
                const updatedActivities = topic.activities.filter((activity) => {
                    const dokActivityItem = dokTopicItem?.activities.find((dokActivity) => dokActivity.id === activity.id);

                    return dokActivityItem?.checked !== CheckboxStates.unchecked;
                });

                return {
                    ...topic,
                    activities: updatedActivities
                };
            }).filter((topic) => topic !== null && topic.activities.length > 0) || [];

            return {
                ...strand,
                topics: updatedTopics
            };
        }).filter((strand) => strand !== null && strand.topics.length > 0) || []
    };
    const getUpdatedCourseInitialState = (course: CourseData): CourseData => {
        if (!course) return course;

        return {
            ...course,
            topics: course?.topics.map((strand, index) => ({
                ...strand,
                isExpanded: index === 0,
                topics: strand.topics?.map((topics) => ({
                    ...topics,
                    isExpanded: index === 0
                })) || []
            }))
        };
    };

    return {
        updatedCourse: getUpdatedCourseInitialState(updatedCourse),
        updatedFilterLevel: updatedFilterLevel({ course: updatedCourse })
    };
};
