import {
    dropdownButtonBorderRadius,
    dropdownContainerFontSize,
    dropdownContainerHeight,
    dropdownContainerWidth
} from '@components/dropdown/dropdown.styled';
import { DropdownVariant } from '@components/dropdown/dropdown.types';

const dropdownButtonBaseStyle = {
    bgColor: 'white.0',
    color: 'neutral.900',
    w: dropdownContainerWidth,
    h: dropdownContainerHeight,
    justifyContent: 'space-between',
    borderRadius: 0,
    fontSize: dropdownContainerFontSize,
    fontWeight: 'normal',
    lineHeight: 6,
    _disabled: {
        opacity: '0.5',
        pointerEvents: 'none'
    }
};
const dropdownButtonDefaultHover = { bgColor: 'neutral.50' };
const dropdownButtonDefaultExpanded = {
    color: 'primary.400',
    borderRadius: dropdownButtonBorderRadius
};

export const DropdownButtonVariants = {
    [DropdownVariant.halfStroke]: {
        ...dropdownButtonBaseStyle,
        borderBottom: '1px solid',
        borderColor: 'neutral.200',
        _hover: {
            marginTop: '1px',
            borderBottom: '2px solid',
            borderColor: 'neutral.300',
            borderRadius: dropdownButtonBorderRadius,
            ...dropdownButtonDefaultHover
        },
        _expanded: {
            marginTop: '1px',
            borderBottom: '2px solid',
            borderColor: 'primary.400',
            ...dropdownButtonDefaultExpanded
        }
    },
    [DropdownVariant.fullStroke]: {
        ...dropdownButtonBaseStyle,
        border: '1px solid',
        borderColor: 'neutral.200',
        borderRadius: '6px',
        paddingInlineStart: '16px !important',
        _hover: {
            border: '1px solid',
            borderColor: 'neutral.300',
            ...dropdownButtonDefaultHover
        },
        _expanded: {
            marginTop: '1px',
            border: '2px solid',
            borderColor: 'primary.400',
            borderRadius: '6px',
            color: 'primary.400'
        }
    },
    [DropdownVariant.fullStrokeVertical]: {
        ...dropdownButtonBaseStyle,
        h: dropdownContainerHeight.tablet,
        border: '1px solid',
        borderColor: 'neutral.200',
        borderRadius: '6px',
        paddingInlineStart: '16px !important',
        _hover: {
            border: '1px solid',
            borderColor: 'neutral.300',
            ...dropdownButtonDefaultHover
        },
        _expanded: {
            marginTop: '1px',
            border: '2px solid',
            borderColor: 'primary.400',
            borderRadius: '6px',
            color: 'primary.400'
        }
    }
};
