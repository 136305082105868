export enum ChallengesAssignmentResultType {
    highestTeacherAssigned = 0,
    highestVoluntary,
    highest,
}

export const LevelFilter = {
    SELECT_ALL: 0,
    MIN_LEVEL: 2,
    MAX_LEVEL: 11
};
