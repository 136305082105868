import { manageGridCells } from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    updateGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import {
    StudentId,
    TopicId
} from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    GridCellActivityId,
    GridCells,
    GridCounters
} from '@containers/activity-router/routes/helpers/slice/topic/topic-slice.types';

type ToggleGridCellsProps = {
    gridCells: GridCells
    gridCounters: GridCounters
    studentId: StudentId
    topicId: TopicId
    activityId: GridCellActivityId
    isSelected: boolean
};

export const toggleGridCells = ({
    gridCells,
    gridCounters,
    studentId,
    topicId,
    activityId,
    isSelected
}: ToggleGridCellsProps) => {
    gridCells = manageGridCells({
        topicId,
        studentId,
        activityId,
        gridCells,
        isSelected
    });
    gridCounters = updateGridCellCounters({
        counters: gridCounters,
        isSelected,
        studentId,
        topicId,
        activityId
    });

    return {
        gridCells,
        gridCounters
    };
};
