import { SetCourseExpandCollapse } from '../../../data-grid-container/courses/types/courses.types';
import {
    TransformCourse,
    TransformCourseTopics
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

type ManageCourseIsExpanded = {
    course: TransformCourse
    payload: SetCourseExpandCollapse
};

type ManageCourseExpandCollapse = {
    course: TransformCourse
    isExpanded: boolean
};

export function manageCollapseStrandChildTopics (strand: TransformCourseTopics, isExpanded: boolean) {
    return {
        ...strand,
        topics: strand.topics?.map((topic) => {
            return {
                ...topic,
                isExpanded
            };
        }) || null,
        isExpanded
    };
}

export function manageCourseStrandIsExpanded ({
    course,
    payload
}: ManageCourseIsExpanded) {
    const {
        strandId,
        topicId,
        isExpanded
    } = payload;

    return course.topics.map((strand) => {
        if (strand.id === strandId) {
            if (topicId) {
                const updatedTopics = strand.topics?.map((topic) =>
                    topic.id === topicId ? {
                        ...topic,
                        isExpanded
                    } : topic
                ) || null;

                return {
                    ...strand,
                    topics: updatedTopics
                };
            } else {
                if (!isExpanded) strand = manageCollapseStrandChildTopics(strand, isExpanded);

                return {
                    ...strand,
                    isExpanded
                };
            }
        }

        return strand;
    });
}

export function manageCourseTopicIsExpanded ({
    course,
    payload
}: ManageCourseIsExpanded) {
    return course.topics.map((topic) => {
        if (topic.id === payload.topicId) {
            return {
                ...topic,
                isExpanded: payload.isExpanded
            };
        }

        return topic;
    });
}

export function manageCourseTopicExpandCollapse ({
    course,
    isExpanded
}: ManageCourseExpandCollapse) {
    if (course) {
        course = {
            ...course,
            topics: course.topics.map((topic) => {
                topic.isExpanded = isExpanded;

                return topic;
            })
        };
    }

    return course;
}

export function manageCourseTopicExpandCollapseCheck ({
    course,
    isExpanded
}: ManageCourseExpandCollapse) {
    return course.topics.every((topic) => topic.isExpanded === !isExpanded);
}
