import {
    getActivityKey,
    parseActivityKey, parseTopicKey
} from '@containers/activity-router/routes/helpers/keys/key-generator';
import { ActivitiesFilterComplexityCounters } from '@containers/activity-router/routes/learn/activities/activities.types';
import {
    ManageActivitiesFiltersComplexityCountersParams,
    ManageComplexityCountersParams,
    ManageConditionalChecksParams,
    ManageGridCellActivityComplexityCountersParams,
    ManageGridCellTopicComplexityCountersParams,
    ManageSuccessCallbackParams,
    UpdateComplexityCountersByGridCellsParams,
    UpdateComplexityCountersParams
} from '@containers/activity-router/routes/learn/activities/helpers/slice/filters/complexity/filters-complexity-counters-helpers.types';
import { ActivitiesComplexity } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const {
    all,
    core,
    easier
} = ActivitiesComplexity;

/**
 * OPTIMISATION NOTE: these function can be integrated with the grid cell loop eventually.
 * Have intentionally kept separate until full scope of grid cell management is completed.
 */
export function manageSelectedComplexityCounters ({
    activityId,
    studentId,
    complexityCounters,
    complexity
}: ManageComplexityCountersParams) {
    if (activityId in complexityCounters[complexity]) {
        if (!complexityCounters[complexity][activityId].includes(studentId)) {
            complexityCounters[complexity][activityId].push(studentId);
        }
    } else {
        complexityCounters[complexity] = {
            ...complexityCounters[complexity],
            [activityId]: [studentId]
        };
    }

    return complexityCounters;
}

function manageRemovedComplexityCounters ({
    activityId,
    studentId,
    complexityCounters,
    complexity
}: ManageComplexityCountersParams) {
    if (complexityCounters?.[complexity]?.[activityId]) {
        complexityCounters[complexity][activityId] = complexityCounters[complexity][activityId].filter(
            (item) => item !== studentId
        );

        if (complexityCounters[complexity][activityId].length === 0) {
            delete complexityCounters[complexity][activityId];
        }
    }

    return complexityCounters;
}

function updateComplexityCounters ({
    complexity,
    isSelected,
    complexityCounters,
    activityId,
    studentId
}: UpdateComplexityCountersParams) {
    const defaultProps = {
        activityId,
        studentId,
        complexityCounters,
        complexity
    };

    if (isSelected) {
        complexityCounters = manageSelectedComplexityCounters(defaultProps);
    } else {
        complexityCounters = manageRemovedComplexityCounters(defaultProps);
    }

    return complexityCounters;
}

function checkConditionalValue (value: any, checkedValue: any) {
    return value === undefined || value === checkedValue;
}

function hasSelectedStateUpdated (prevSelected: boolean, currentSelected: boolean) {
    return prevSelected !== currentSelected;
}

function manageConditionalChecks ({
    gridCells,
    prevGridCells,
    course,
    updatedStudentId,
    updatedTopicId,
    updatedActivityId,
    complexityCounters,
    successCallback
}: ManageConditionalChecksParams) {
    Object.keys(gridCells).forEach((studentId) => {
        if (checkConditionalValue(updatedStudentId, studentId)) {
            Object.keys(gridCells[studentId]).forEach((topicId) => {
                const topicGridCells = gridCells[studentId][topicId];

                if (checkConditionalValue(updatedTopicId, topicId)) {
                    const parsedTopicId = parseTopicKey(topicId).topicId;
                    const selectedTopic = course?.topics.find((topic) => topic.id === parsedTopicId);

                    Object.keys(topicGridCells).forEach((activityId) => {
                        if (checkConditionalValue(updatedActivityId, activityId) && selectedTopic) {
                            complexityCounters = successCallback({
                                studentId,
                                topicId,
                                activityId,
                                selectedTopic,
                                prevGridCells,
                                gridCells,
                                complexityCounters
                            });
                        }
                    });
                }
            });
        }
    });

    return complexityCounters;
}

function manageSuccessCallback ({
    studentId,
    topicId,
    activityId,
    selectedTopic,
    prevGridCells,
    gridCells,
    complexityCounters
}: ManageSuccessCallbackParams) {
    const { isSelected: prevIsSelected } = prevGridCells[studentId][topicId][activityId];
    const { isSelected } = gridCells[studentId][topicId][activityId];

    if (hasSelectedStateUpdated(prevIsSelected, isSelected)) {
        const {
            id,
            activityType
        } = parseActivityKey(activityId);
        const selectedActivity = selectedTopic?.activities?.find((activity) =>
            activity.id === id &&
            activity.activityType === activityType
        );
        const { isSelected } = gridCells[studentId][topicId][activityId];

        if (selectedActivity) {
            complexityCounters = updateComplexityCounters({
                complexity: selectedActivity.complexity as ActivitiesComplexity,
                isSelected,
                complexityCounters,
                activityId,
                studentId
            });
        }
    }

    return complexityCounters;
}

function updateComplexityCountersByGridCells (params: UpdateComplexityCountersByGridCellsParams) {
    params.complexityCounters = manageConditionalChecks({
        ...params,
        successCallback: (props) => manageSuccessCallback(props)
    });

    return params.complexityCounters;
}

function manageGridCellTopicComplexityCounters ({
    selectedTopic,
    prevGridCells,
    gridCells,
    studentId,
    topicId,
    complexityCounters
}: ManageGridCellTopicComplexityCountersParams) {
    selectedTopic?.activities?.forEach((activity) => {
        const activityId = getActivityKey({
            id: activity.id,
            activityType: activity.activityType
        });
        const { isSelected: prevIsSelected } = prevGridCells[studentId][topicId][activityId];
        const { isSelected } = gridCells[studentId][topicId][activityId];

        if (hasSelectedStateUpdated(prevIsSelected, isSelected)) {
            complexityCounters = updateComplexityCounters({
                complexity: activity.complexity as ActivitiesComplexity,
                isSelected,
                complexityCounters,
                activityId,
                studentId
            });
        }
    });

    return complexityCounters;
}

function manageGridCellActivityComplexityCounters ({
    prevGridCells,
    gridCells,
    studentId,
    activityId,
    topicId,
    selectedTopic,
    complexityCounters
}: ManageGridCellActivityComplexityCountersParams) {
    const {
        id,
        activityType
    } = parseActivityKey(activityId);
    const { isSelected: prevIsSelected } = prevGridCells[studentId][topicId][activityId];
    const { isSelected } = gridCells[studentId][topicId][activityId];

    if (hasSelectedStateUpdated(prevIsSelected, isSelected)) {
        const selectedActivity = selectedTopic?.activities?.find((activity) =>
            activity.id === id &&
            activity.activityType === activityType
        );

        if (selectedActivity) {
            complexityCounters = updateComplexityCounters({
                complexity: selectedActivity.complexity as ActivitiesComplexity,
                isSelected,
                complexityCounters,
                activityId,
                studentId
            });
        }
    }

    return complexityCounters;
}

export function manageActivitiesGridCellFiltersComplexityCounters ({
    course,
    topicId,
    activityId,
    complexityCounters,
    gridCells,
    studentId,
    prevGridCells
}: ManageActivitiesFiltersComplexityCountersParams) {
    if (course) {
        const parsedTopicId = parseTopicKey(topicId).topicId;
        const selectedTopic = course.topics.find((topic) => topic.id === parsedTopicId);
        const defaultProps = {
            selectedTopic,
            prevGridCells,
            gridCells,
            studentId,
            topicId,
            complexityCounters
        };

        if (!activityId) {
            complexityCounters = manageGridCellTopicComplexityCounters(defaultProps);
        } else if (activityId) {
            complexityCounters = manageGridCellActivityComplexityCounters({
                ...defaultProps,
                activityId
            });
        }
    }

    return complexityCounters;
}

export function manageActivitiesGridCheckboxFiltersComplexityCounters ({
    gridCells,
    course,
    updatedStudentId,
    updatedTopicId,
    updatedActivityId,
    complexityCounters,
    prevGridCells
}: UpdateComplexityCountersByGridCellsParams) {
    complexityCounters = updateComplexityCountersByGridCells({
        gridCells,
        course,
        updatedTopicId,
        updatedActivityId,
        updatedStudentId,
        complexityCounters,
        prevGridCells
    });

    return complexityCounters;
}

export function resetActivitiesFiltersComplexityCounters (complexityCounters: ActivitiesFilterComplexityCounters) {
    complexityCounters = {
        [all]: {},
        [core]: {},
        [easier]: {}
    };

    return complexityCounters;
}
